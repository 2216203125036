import React from 'react';
import { useStyletron } from 'baseui';
import { Card } from 'baseui/card';
import { Button } from 'baseui/button';
import { styled } from 'styletron-react';


const Tickets = () => {
    const [css, theme] = useStyletron();
    const StyledCard = styled(Card, {
        fontFamily: 'Montserrat, sans-serif',
    });


    const cardStyle = css({
        width: '300px',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%',
        backgroundColor: theme.colors.backgroundPrimary,
        color: theme.colors.contentPrimary,
        boxShadow: theme.lighting.shadow600,
        fontFamily: 'Montserrat, sans-serif',
    });


    const openEventPage3 = () => {
        window.open('https://www.manitobamusic.com/news/read,article/9023/manitoba-music-and-wag-qaumajuq-celebrate-black-music-makers-with-concert-mixer?fbclid=PAZXh0bgNhZW0CMTEAAaawVkkKhDXRj-UqPy6ElWyOg6_g2sU_jANPkkqk6JX_xwcgKLtSxAuIwQc_aem_O2cTVLCmKBI1CsrGJM7GUg', '_blank');
    };

    const buyNowButtonStyle = css({
        marginTop: 'rem',
        backgroundColor: theme.colors.buttonPrimaryFill,
        color: theme.colors.buttonPrimaryText,
        ':hover': {
            backgroundColor: theme.colors.buttonPrimaryHover,
            color: theme.colors.buttonPrimaryText,
        },
        fontFamily: 'Montserrat, sans-serif',
    });

    return (
        <div id = "ticketList"
            className={css({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', // added
                flexWrap: 'wrap', // added
                background: 'linear-gradient(180deg, #EA2B1F 0%, #EDAE49 48.55%, #F9DF74 100%) no-repeat fixed',
                minHeight: '100vh',
                marginBottom: '0',
                padding: '0 0.5rem', // added

                '@media (max-width: 760px)': {
                    marginBottom: '0',
                },
            })}
        >
            <h1
                className={css({
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '3rem',
                    margin: '2rem 0 0.5rem',
                    color: '#fff',
                    textAlign: 'center', // Add this line
                })}
            >
                CATCH ME AT THESE
            </h1>
            <h2
                className={css({
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'normal',
                    fontSize: '1.5rem',
                    margin: '0 0 2rem',
                    color: '#fff',
                    textAlign: 'center',
                })}
            >
                Riski's upcoming events
            </h2>
            <StyledCard className={cardStyle}>
                <div>
                    <h3>Manitoba Music and WAG-Qaumajug Celebrate Black Music Makers with Concert & Mixer</h3>
                    <p>September 13th, 2024</p>
                    <p>7pm - 9pm</p>
                    <Button onClick={openEventPage3} className={buyNowButtonStyle}>
                        More Info
                    </Button>
                </div>
            </StyledCard>
        </div>);
};
export default Tickets;
