import React, { useState, useEffect } from 'react';
import { Button } from 'baseui/button';
import { useStyletron } from 'baseui';
import logoImage from '../logo.png'; // Import your logo image here
import { Link } from 'react-scroll'; // Import Link from react-scroll

const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [css] = useStyletron();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    const closeButtonStyles = {
        display: isMobile && drawerOpen ? 'block' : 'none',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '5px 10px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 760);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const drawerStyles = {
        display: drawerOpen ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: isMobile ? '66.66%' : '100%',
        backgroundColor: 'white',
        zIndex: 2,
        overflowY: 'scroll',
    };

    const menuButtonStyles = {
        display: isMobile ? 'block' : 'none',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
    };

    const headerButtonStyles = css({
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '0.5rem 2rem',
        borderRadius: '30px',
        backgroundColor: '#EA2B1F',
        backgroundImage: 'linear-gradient(180deg, #EA2B1F, #EDAE49)',
        color: '#000',
        ':hover': {
            backgroundColor: '#EDAE49',
            backgroundImage: 'linear-gradient(180deg, #EDAE49, #EA2B1F)',
        },
    });

    const linkStyle = {
        color: 'black',
        textDecoration: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        ':hover': {
            textDecoration: 'underline',
        },
    };

    const handleLinkClick = (to) => {
        handleCloseDrawer();
        to();
    };

    return (
        <>
            <header id="top"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#120603',
                        padding: '10px 20px',
                        fontFamily: 'Montserrat, sans-serif',
                        zIndex: 2,
                        position: 'relative',
                    }}
            >
                <Link to="top" smooth={true} duration={500}>
                    <img
                        src={logoImage}
                        alt="Logo"
                        style={{ width: 'auto', height: '70px', cursor: 'pointer' }}
                    />
                </Link>

                <nav style={{ display: isMobile ? 'none' : 'flex', alignItems: 'center' }}>
                    <Link
                        to="playlist"
                        smooth={true}
                        duration={500}
                        style={{ color: 'white', textDecoration: 'none', margin: '0 10px', cursor: 'pointer' }}
                    >
                        Playlist
                    </Link>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                        style={{ color: 'white', textDecoration: 'none', margin: '0 10px', cursor: 'pointer' }}
                    >
                        Contact Riski
                    </Link>
                    <Link
                        to="riskiOnYoutube"
                        smooth={true}
                        duration={500}
                        style={{ color: 'white', textDecoration: 'none', margin: '0 10px', cursor: 'pointer' }}
                    >
                        Riski on YouTube
                    </Link>
                    <Link to="ticketList" smooth={true} duration={500}>
                        <Button className={headerButtonStyles} style={{ margin: '10px 20px' }}>
                            Next Event
                        </Button>
                    </Link>
                </nav>
                <button onClick={toggleDrawer} style={menuButtonStyles}>☰</button>
            </header>
            <div style={drawerStyles}>
                <button onClick={handleCloseDrawer} style={closeButtonStyles}>×</button>
                <nav style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Link
                        to="playlist"
                        smooth={true}
                        duration={500}
                        style={linkStyle}
                        onClick={() => handleLinkClick(() => {})}
                    >
                        Playlist
                    </Link>
                    <Link
                        to="contact"
                        smooth={true}
                        duration={500}
                        style={linkStyle}
                        onClick={() => handleLinkClick(() => {})}
                    >
                        Contact Riski
                    </Link>
                    <Link
                        to="riskiOnYoutube"
                        smooth={true}
                        duration={500}
                        style={linkStyle}
                        onClick={() => handleLinkClick(() => {})}
                    >
                        Riski on YouTube
                    </Link>
                    <Link to="ticketList" smooth={true} duration={500} onClick={() => handleLinkClick(() => {})}>
                        <Button className={headerButtonStyles} style={{ margin: '10px 20px' }}>
                            Next Event
                        </Button>
                    </Link>
                </nav>
            </div>
        </>
    );
};

export default Header;
