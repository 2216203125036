import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, LightTheme } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import App from './App';
import './index.css';

const customTheme = {
    ...LightTheme,
    typography: {
        ...LightTheme.typography,
        font1100: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '48px',
        },
        // Add other font styles for Montserrat if needed
    },
    colors: {
        ...LightTheme.colors,
        // Add or override any colors you need
        primary: '#61210F',
        primary50: '#EA2B1F',
        primary100: '#EDAE49',
        primary200: '#F9DF74',
    },
};

const engine = new Styletron();

// Use createRoot from 'react-dom/client'
const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
            <StyletronProvider value={engine}>
                <ThemeProvider theme={customTheme}>
                    <App />
                </ThemeProvider>
            </StyletronProvider>
    </React.StrictMode>
);
