import React from 'react';
import './SpotifyPlaylist.css';

const SpotifyPlaylist = ({ playlistId }) => {
    const src = `https://open.spotify.com/embed/playlist/2EO1IaVr0KervUv5UelAiL?`;

    return (
        <div id="playlist" className="spotify-playlist-container">
            <h1 className="spotify-playlist-title">RISKI'S PLAYLIST</h1>
            <iframe
                className="spotify-iframe"
                src={src}
                width="100%"
                height="380"
                frameBorder="0"
                allowTransparency="true"
                allow="encrypted-media"
                title="Spotify Playlist"
            ></iframe>
        </div>
    );
};

export default SpotifyPlaylist;
