import React from 'react';
import { useStyletron } from 'baseui';
import riskiImage from '../riski.JPG';

const Banner = () => {
    const [css] = useStyletron();

    return (
        <div
            className={css({
                position: 'relative',
                zIndex: 1,
                height: '100vh', // Full viewport height
                width: '100vw', // Full viewport width
                padding: '2rem 2rem 10rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                textAlign: 'center',
                background:
                    'linear-gradient(180deg, #EA2B1F 0%, #EDAE49 48.55%, #F9DF74 100%) no-repeat fixed',
                boxSizing: 'border-box',
                fontFamily: 'Montserrat, sans-serif',

                '@media (max-width: 760px)': {
                    padding: '2rem 1rem 6rem',
                },
            })}
        >
            <img
                src={riskiImage} // Use the new image here
                alt=""
                className={css({
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: '50% 62%', // Align image to the bottom
                    zIndex: -1,
                })}
            />
        </div>
    );
};

export default Banner;