import React from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import TicketList from "../components/TicketList";
import SpotifyPlaylist from "../components/SpotifyPlaylist";
import RiskiOnYouTube from "../components/RiskiOnYoutube";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
            <Header/>
            <Banner />
            <TicketList/>
            <RiskiOnYouTube/>
            <SpotifyPlaylist spotifyUri="YOUR_PLAYLIST_URI" />
            <ContactSection/>
            <Footer/>

            {/* ...other components */}
        </>
    );
};

export default Home;
