import React from 'react';
import { useStyletron } from 'baseui';

const RiskiOnYouTube = () => {
    const [css] = useStyletron();

    const sectionStyle = css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(180deg, #EA2B1F 0%, #EDAE49 48.55%, #F9DF74 100%) no-repeat fixed',
        '@media (max-width: 760px)': {
            minHeight: '100%',
            padding: '2rem 1rem',
        },
    });

    const videos = [
        {
            id: '2EWBSYskGOU',
            title: 'APARTMENT 300 - DJ RISKI | Amapiano Afrobeats mix | May 2024',
        },
        {
            id: 'kgzRRJdWejY',
            title: 'APARTMENT 300 - DJ RISKI | Amapiano Afrobeats mix | June 2024\n',
        },
        {
            id: 'R_RVv6mRTyg',
            title: 'APARTMENT 300 - DJ RISKI | Afrobeats and Amapiano Mix | July 2024',
        },
    ];

    return (
        <div id="riskiOnYoutube" className={sectionStyle}>
            <h1
                className={css({
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '3rem',
                    margin: '2rem 0 1rem',
                    color: '#fff',
                    textAlign: 'center',
                })}
            >
                RISKI ON YOUTUBE
            </h1>
            <div
                className={css({
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                })}
            >
                {videos.map((video) => (
                    <a
                        key={video.id}
                        href={`https://www.youtube.com/watch?v=${video.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={css({
                            margin: '1rem',
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '30%',
                            '@media (max-width: 660px)': {
                                width: '70%',
                            },
                            '@media (min-width: 661px) and (max-width: 1024px)': {
                                width: '45%',
                            },
                        })}
                    >
                        <img
                            src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`}
                            alt={video.title}
                            className={css({
                                width: '100%',
                                borderRadius: '8px',
                            })}
                        />
                        <h3
                            className={css({
                                fontFamily: 'Montserrat, sans-serif',
                                fontWeight: 'normal',
                                fontSize: '1.5rem',
                                margin: '0 0 2rem',
                                color: '#fff',
                                textAlign: 'center',
                            })}
                        >
                            {video.title}
                        </h3>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default RiskiOnYouTube;
