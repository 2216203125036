import React from 'react';
import { useStyletron } from 'baseui';
import footerLogo from "../logo.png";

const Footer = () => {
    const [css] = useStyletron();

    const sectionStyle = css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(180deg, #EA2B1F 0%, #EDAE49 48.55%, #F9DF74 100%) no-repeat fixed',
        '@media (max-width: 760px)': {
            minHeight: '100%',
            padding: '2rem 1rem',
        },
    });

    return (
        <div className={sectionStyle}>
            <img
                src={footerLogo}
                alt="footer"
                className={css({
                    width: '10%',
                    '@media (max-width: 660px)': {
                        width: '20%',
                    },
                    '@media (min-width: 661px) and (max-width: 1024px)': {
                        width: '20%',
                    },
                })}
            />
        </div>
    );
};

export default Footer;
