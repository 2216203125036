import React from 'react';
import { useStyletron } from 'baseui';

const ContactSection = () => {
    const [css] = useStyletron();

    const sectionStyle = css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh', // Adjusted minHeight
        background: 'linear-gradient(180deg, #EA2B1F 0%, #EDAE49 48.55%, #F9DF74 100%) no-repeat fixed',
        '@media (max-width: 760px)': {
            minHeight: '100%',
            padding: '2rem 1rem',
        },
    });

    const headerStyle = css({
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        fontSize: '3rem',
        margin: '2rem 0 1rem',
        color: '#fff',
    });

    const subtitleStyle = css({
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '1.5rem',
        marginBottom: '2rem',
        color: '#fff',
        '@media (max-width: 760px)': {
            fontSize: '1.2rem', // Adjusted fontSize for smaller screens
            textAlign: 'center', // Added text-align for better alignment
        },
    });

    return (
        <div id = "contact" className={sectionStyle}>
            <h1 className={headerStyle}>CONTACT</h1>
            <h3 className={subtitleStyle}>Contact bookdjriski@gmail.com for bookings, questions and concerns</h3>
        </div>
    );
};

export default ContactSection;
